import BarbaView from 'abstractions/BarbaView'
import Font from 'components/Font'
import FontTester from 'components/FontTester'

const PERSISTENT = {}

export default new BarbaView('font', {
  beforeEnter: function ({ next }) {
    this.refs.font = new Font({
      openTab: PERSISTENT.openTab
    })
    this.refs.font.hydrate(next.container.querySelector('.font'))

    this.refs.fontTester = new FontTester({
      text: PERSISTENT.text,
      scale: PERSISTENT.scale
    })
    this.refs.fontTester.hydrate(next.container.querySelector('.font-tester'))
  },

  afterEnter: function ({ next }) {},

  afterLeave: function ({ next }) {
    if (next.namespace === 'font') {
      PERSISTENT.openTab = this.refs.font.openTab.id
      PERSISTENT.text = this.refs.fontTester.text
      PERSISTENT.scale = this.refs.fontTester.scale
    } else {
      for (const key in PERSISTENT) delete PERSISTENT[key]
      document.body.classList.remove('is-inverted')
    }
  }
})
