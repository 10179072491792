import DomComponent from 'abstractions/DomComponent'

export default class Filters extends DomComponent {
  didInit (props) {
    this.handleTabStateChange = this.handleTabStateChange.bind(this)
    this.handleStyleChange = this.handleStyleChange.bind(this)
  }

  get openTab () {
    return this.refs.tabs.find(tab => tab.checked)
  }

  didMount (props, state) {
    this.refs.fontTesterContent = this.refs.base.querySelector('.font-tester [contenteditable]')
    this.refs.fontStyleName = this.refs.base.querySelector('.font__style-name')
    this.refs.tabs = Array.from(this.refs.base.querySelectorAll('.font__tab-state'))
    this.refs.styles = Array.from(this.refs.base.querySelectorAll('.font__style a'))

    for (const tab of this.refs.tabs) {
      tab.checked = (tab.id === props.openTab)
      tab.addEventListener('change', this.handleTabStateChange)
    }

    for (const style of this.refs.styles) {
      style.addEventListener('click', this.handleStyleChange)
    }

    this.loadFOICHack()
  }

  handleTabStateChange (e) {
    // Allow only one tab to be checked at a given time
    for (const tab of this.refs.tabs) {
      tab.checked = (tab === e.target && e.target.checked)
    }
  }

  handleStyleChange (e) {
    // Hijack link to change style instead of navigating to next page
    e.preventDefault()
    e.stopPropagation()

    for (const style of this.refs.styles) {
      style.classList.toggle('is-active', style === e.target)
    }

    this.refs.fontStyleName.innerText = e.target.innerText
    this.refs.fontTesterContent.style.fontFamily = `"${e.target.dataset.cssName}"`
  }

  loadFOICHack () {
    const preloader = document.createElement('div')
    for (const style of this.refs.styles) {
      const div = document.createElement('div')
      div.innerText = 'a'
      div.style.fontFamily = `"${style.dataset.cssName}"`
      div.style.fontSize = 0
      preloader.appendChild(div)
    }

    this.refs.base.appendChild(preloader)
  }
}
