import DomComponent from 'abstractions/DomComponent'

export default class Filters extends DomComponent {
  didInit (props) {
    this.handlePaste = this.handlePaste.bind(this)
    this.handleInput = this.handleInput.bind(this)
    this.handleInvertChange = this.handleInvertChange.bind(this)
    this.handleScaleChange = this.handleScaleChange.bind(this)
    this.handleScaleDblclick = this.handleScaleDblclick.bind(this)
  }

  get text () { return this.refs.tester.textContent }
  get scale () { return +this.refs.scale.value }

  didMount (props, state) {
    this.refs.invert = this.refs.base.querySelector('#invert')
    this.refs.invert.addEventListener('change', this.handleInvertChange)

    this.refs.scale = this.refs.base.querySelector('#scale')
    this.refs.scale.addEventListener('input', this.handleScaleChange)
    this.refs.scale.addEventListener('dblclick', this.handleScaleDblclick)
    if (props.scale) {
      this.refs.scale.value = props.scale
      this.handleScaleChange()
    }

    this.refs.tester = this.refs.base.querySelector('[contenteditable]')
    this.refs.tester.addEventListener('paste', this.handlePaste)
    this.refs.tester.addEventListener('input', this.handleInput)
    if (props.text) this.refs.tester.textContent = props.text
  }

  handleInput (e) {
    // Clean up the tester if no text, but eventually empty <p>, <br>, etc…
    if (!this.refs.tester.innerText.trim()) this.refs.tester.innerHTML = ''
  }

  handleInvertChange (e) {
    document.body.classList.toggle('is-inverted', e.target.checked)
  }

  handleScaleChange () {
    window.requestAnimationFrame(() => {
      this.refs.base.style.setProperty('--font-scale', this.scale)
    })
  }

  handleScaleDblclick () {
    this.refs.scale.value = 1
    this.handleScaleChange()
  }

  handlePaste (e) {
    // Strip style of a pasted text
    e.preventDefault()
    const value = e.clipboardData.getData('text/plain')
    window.document.execCommand('insertText', false, value)
  }
}
