export default class DomComponent {
  constructor (props = {}) {
    // Add your DOM references to this.refs
    this.refs = {}
    this.props = props
    this.state = {}
    this.didInit(this.props)
  }

  // Called after the component is instantiated
  didInit () {}

  // Called just before the component is mounted to the DOM
  willMount () {}

  // Called just after the component is mounted to the DOM
  didMount () {}

  // Called just before the component is removed from the DOM
  willUnmount () {}

  callWillMount () {
    if (!this.refs.base) return
    this.willMount(this.refs.base)
  }

  callDidMount () {
    if (!this.refs.base) return
    this.mounted = true
    this.didMount(this.props, this.state)
  }

  // Use a already existing DOM element as base for the component
  hydrate (el) {
    if (!el || this.mounted) return
    this.callWillMount(el)
    this.refs.base = el
    this.mounted = true
    this.callDidMount()
  }

  // Remove the DOM and destroy the component
  destroy ({ removeBase = true } = {}) {
    if (!this.mounted) return
    this.willUnmount(this.refs.base)
    if (removeBase) {
      this.refs.base && this.refs.base.parentNode && this.refs.base.parentNode.removeChild(this.refs.base)
    }
    for (let k in this.refs) {
      if (this.refs[k] && this.refs[k].destroy) this.refs[k].destroy()
      delete this.refs[k]
    }
    for (let k in this.state) delete this.state[k]

    this.refs = undefined
    this.state = undefined
    this.props = undefined
    this.mounted = false
  }
}
