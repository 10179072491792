/* global Stripe, fetch, FormData */
import DomComponent from 'abstractions/DomComponent'

export default class Filters extends DomComponent {
  didInit (props) {
    this.updatePrices = this.updatePrices.bind(this)
    this.updateSubmit = this.updateSubmit.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleFamilyChange = this.handleFamilyChange.bind(this)
    this.handleStyleChange = this.handleStyleChange.bind(this)
  }

  didMount (props, state) {
    this.refs.total = this.refs.base.querySelector('.buy__total')
    this.refs.submit = this.refs.base.querySelector('button[type=submit]')

    this.refs.base.addEventListener('submit', this.handleSubmit)
    this.refs.base.addEventListener('change', this.updatePrices)

    // Bind all checkboxes to their corresponding handler
    const checkboxes = this.refs.base.querySelectorAll('input[type=checkbox]')
    for (const checkbox of Array.from(checkboxes)) {
      const onChange = checkbox.value === 'full'
        ? this.handleFamilyChange
        : this.handleStyleChange
      checkbox.addEventListener('change', onChange)
      onChange({ target: checkbox })
    }

    // Bind all required fields to the updateSubmit
    this.refs.requireds = this.refs.base.querySelectorAll('[required]')
    for (const required of Array.from(this.refs.requireds)) {
      required.addEventListener('input', this.updateSubmit)
      required.addEventListener('change', this.updateSubmit)
    }

    this.updatePrices()
  }

  // Select/deselect all styles based on the state of the 'Full family' checkbox
  handleFamilyChange (e) {
    const name = e.target.dataset.font
    const styles = this.refs.base.querySelectorAll(`input[name^='fonts[${name}]']`)
    for (const style of Array.from(styles)) {
      style.checked = e.target.checked
    }
  }

  // Update 'Full family' state based on styles selection
  handleStyleChange (e) {
    const name = e.target.dataset.font
    const full = this.refs.base.querySelector(`input[name^='fonts[${name}][full]']`)
    if (!full) return

    const styles = this.refs.base.querySelectorAll(`input[name^='fonts[${name}]']:not([value=full])`)
    const isFull = !Array.from(styles).find(style => !style.checked)

    full.checked = isFull
  }

  updatePrices () {
    const license = this.refs.base.querySelector(`[name=license]:checked`)
    if (!license) return

    // Update all prices based on which license is selected
    const percent = +(license.dataset.percent || 100) / 100
    const prices = this.refs.base.querySelectorAll('[data-base-price]')
    for (const price of Array.from(prices)) {
      const basePrice = price.dataset.basePrice
      price.dataset.price = basePrice * percent
    }

    // Update total based on which styles are selected
    const fonts = this.refs.base.querySelectorAll('.buy__font')
    let total = 0
    for (const font of Array.from(fonts)) {
      const full = font.querySelector('input[value=full]')
      if (full && full.checked) {
        total += +full.dataset.price
        continue
      }

      const styles = font.querySelectorAll('input:not([value=full])')
      for (const style of Array.from(styles)) {
        if (style.checked) total += +style.dataset.price
      }
    }
    this.refs.total.dataset.price = total

    this.updateSubmit()
  }

  updateSubmit (e) {
    const invalidInputs = Array.from(this.refs.requireds).filter(input => {
      return !(input.type === 'checkbox'
        ? input.checked
        : input.value
      )
    })

    const isValid = parseInt(this.refs.total.dataset.price) && !invalidInputs.length
    this.refs.submit.toggleAttribute('disabled', !isValid)
  }

  async handleSubmit (e) {
    e.preventDefault()

    if (!parseInt(this.refs.total.dataset.price)) {
      window.alert('Your cart is empty')
      return
    }

    try {
      const formData = new FormData(this.refs.base)
      const response = await fetch('/buy/create-checkout-session', {
        method: 'POST',
        body: new URLSearchParams(formData)
      })

      const session = await response.json()
      if (session.status === 'error') throw new Error(session.message)

      const stripe = new Stripe(window.MAMATYPE_STRIPE_PUBKEY)
      const { error } = await stripe.redirectToCheckout({ sessionId: session.id })
      if (error) throw error
    } catch (error) {
      console.error(error)
      window.alert(error.message)
    }
  }
}
