import barba from '@barba/core'

barba.init({
  debug: !window.ENV.production,
  prevent: ({ el }) => el.classList && el.classList.contains('no-barba'),
  views: [
    require('views/buy'),
    require('views/custom'),
    require('views/font'),
    require('views/home')
  ].map(v => v.default),
  transitions: [{
    once: ({ next }) => {
      document.body.classList.remove('is-loading')
    },

    enter: () => {
      window.scrollTo(0, 0)
    }
  }]
})
