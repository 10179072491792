import lozad from 'lozad'
import BarbaView from 'abstractions/BarbaView'

export default new BarbaView('custom', {
  afterEnter: function ({ next }) {
    const lazys = next.container.querySelectorAll('[data-lazyload]')

    // Lozad set a [data-loaded] attribute when loading image, but does not detect
    // when image is fully loaded, which can cause incoherent animations
    for (const image of Array.from(lazys)) {
      image.onload = () => image.setAttribute('data-fully-loaded', true)
    }

    this.refs.lazyload = lozad(lazys)
    this.refs.lazyload.observe()
  }
})
